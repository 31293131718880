import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }


    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #000000;
        font-size: 40px;
        line-height: 1.18;
        font-weight: blod;

        // @media only screen and (max-width: 890px) {
        //   font-size: 47px;
        // }
      
        // @media only screen and (max-width: 414px) {
        //   font-size: 32px;
        // }
    }

    h1  {
        font-size: 65px;
    }

    h2  {
        font-size: 50px;
    }

    p {
        color: #6C6C6C;
        font-size: 17px;        
        line-height: 1.41;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
        
    .ant-collapse-header {
        color: #000000;
        font-weight: bold;
        font-size: 22px;
        padding: 16px 30px;
    }

    .ant-collapse > .ant-collapse-item {
        border-bottom: 2px solid #ECECEC;
    }

    .ant-card {
        border-radius: 20px;
        background: #F9F9F9;
    }

    .ant-card-body {
        padding: 30px 28px;
    }

    .ant-card-bordered {
        border: 1px solid #ECECEC;
    }

    .ant-tag {
        font-size: 18px;
        height: 36px;
        border-radius: 10px;
        padding: 8px 15px;
        font-weight: 600;
    }

    .ant-tag-has-color {
        color: #F01152;
    }
`;
