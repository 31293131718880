const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/edit", "/edit"],
    exact: true,
    component: "Edit",
  },
  {
    path: ["/text", "/text"],
    exact: true,
    component: "Text",
  },
  {
    path: ["/frames", "/frames"],
    exact: true,
    component: "Frames",
  },
];

export default routes;
